<template>
    <base-sticky-heading class="py-6">
        <template #title>
            <h5 class="text-xl font-medium text-gray-900">
                {{ $t('subscriptions.headline') }}
            </h5>
        </template>

        <div v-if="state.loaded" class="flex-1 bg-gray-50 p-6">
            <div
                v-if="expiredSubscription && trialEnds"
                class="flex flex-col items-center"
            >
                <h6 class="text-3xl font-semibold text-gray-900">
                    {{ $t('subscriptions.change.yourFreeTrial') }}
                </h6>
                <i18n-t
                    tag="p"
                    keypath="subscriptions.change.selectAPlanBelow"
                    class="max-w-112 py-6 text-center text-md font-normal text-gray-500"
                >
                    <template #here>
                        <span class="launch-messenger cursor-pointer underline">
                            {{ $t('subscriptions.change.supportTeamHere') }}
                        </span>
                    </template>
                </i18n-t>
            </div>
            <div class="grid grid-cols-3 gap-6">
                <subscriptions-card
                    v-for="plan in plans"
                    :key="plan.id"
                    :plan="plan"
                    :subscription="subscription"
                />
            </div>
        </div>
    </base-sticky-heading>
</template>

<script setup>
const { t } = useI18n()
const emitter = useEmitter()

useHead({ title: t('subscriptions.headline') })

const state = reactive({
    loaded: false,
})
const { company } = useAuth()

const { execute: executeGetSubscription, subscription } = useSubscription()

const expiredSubscription = computed(() => company.value?.expired_subscription)
const trialEnds = computed(() => company.value?.trial_ends)

const { execute: executeGetPlans, result: plans } = useApi(
    '/api/subscription/plans',
    'GET'
)

onMounted(async () => {
    try {
        state.loaded = false
        emitter.emit('set-loading', true)
        await Promise.all([executeGetPlans(), executeGetSubscription()])
    } finally {
        state.loaded = true
        emitter.emit('set-loading', false)
    }
})
</script>
