const navigation = [
    {
        id: 'sas-subscriptions',
        path: 'subscriptions',
        label: 'subscriptions.sidebar.label',
        parent: 'sas-cybooks',
        position: 10,
        permission: 'view_subscriptions',
    },
]

export default navigation
