<template>
    <div class="rounded-md border border-gray-300 bg-white">
        <div class="flex flex-col border-b border-gray-300 p-8">
            <div class="mb-4 flex items-center justify-between gap-2">
                <span class="text-lg font-semibold text-gray-600">
                    {{ plan.name }}
                </span>
                <base-badge
                    v-if="plan.popular"
                    :label="$t('subscriptions.index.popular')"
                    border
                    variant="primary"
                />
            </div>
            <div class="mb-8 flex items-end gap-1">
                <span
                    class="flex items-start text-6xl font-semibold text-gray-900"
                >
                    {{ formattedCalculatedPrices[0]
                    }}<template v-if="formattedCalculatedPrices[1]"
                        >,<small class="text-4xl">{{
                            formattedCalculatedPrices[1]
                        }}</small>
                    </template>
                </span>
                <span class="mb-2 text-md font-medium text-gray-600">
                    {{
                        $t('subscriptions.perType', {
                            oneTime: plan.is_pay_once,
                        })
                    }}
                </span>
            </div>
            <div class="flex h-20 flex-col text-md font-normal text-gray-600">
                <template v-if="discount && !plan.is_pay_once">
                    <span class="text-primary-600">
                        {{
                            $t('subscriptions.change.offForMonths', {
                                percent: discount.percent,
                                months: discount.duration_in_months,
                            })
                        }}
                    </span>
                    <i18n-t keypath="subscriptions.change.wasSave" tag="span">
                        <template #was>
                            <span class="line-through">
                                {{
                                    $filters.symbolCurrency(
                                        plan.price,
                                        plan.currency
                                    )
                                }}
                            </span>
                        </template>
                        <template #price>
                            {{
                                $filters.symbolCurrency(
                                    totalDiscount,
                                    plan.currency
                                )
                            }}
                        </template>
                    </i18n-t>
                </template>
                <span v-else>
                    {{ plan.description }}
                </span>
            </div>
            <template v-if="plan.id === subscription?.id">
                <base-button
                    variant="default"
                    :disabled="!subscription.endsAt"
                    :loading="loadingResumePlan"
                    full-width
                    @click="onResume"
                >
                    {{
                        subscription.endsAt
                            ? $t('subscriptions.change.resumeSubscription')
                            : $t('subscriptions.change.youAreOnThisPlan')
                    }}
                </base-button>
            </template>
            <base-button
                v-else
                variant="primary"
                full-width
                @click="onChangeSubscription(plan)"
            >
                {{
                    plan.order < subscription?.order
                        ? $t('subscriptions.change.downgradeAndSave')
                        : $t('subscriptions.change.upgradeAndSave')
                }}
            </base-button>
        </div>
        <div class="flex flex-col p-8">
            <span class="mb-1 text-md font-semibold uppercase text-gray-900">
                {{ $t('subscriptions.features') }}
            </span>
            <span
                class="mb-6 text-md font-normal text-gray-600"
                v-html="$filters.sanitize(plan.feature_note)"
            />
            <subscriptions-feature-list :features="plan.features" />
        </div>
    </div>
</template>

<script setup>
import { symbolCurrency } from '@tenant/core/filter'

const props = defineProps({
    plan: {
        type: Object,
        required: true,
    },
    subscription: {
        type: Object,
        required: false,
    },
})

const { t } = useI18n()
const router = useRouter()
const { successNotify } = useNotification()

useHead({ title: t('subscriptions.headline') })

const { company } = useAuth()

const { execute: executeResumePlan, loading: loadingResumePlan } = useApi(
    '/api/subscription/resume',
    'POST'
)

const discount = computed(() => company.value?.subscription_discount)

const calculatedPrice = computed(() => {
    if (!props.plan.is_pay_once && discount.value) {
        return (props.plan.price * (100 - discount.value.percent)) / 100
    }

    return props.plan.price
})

const totalDiscount = computed(() => {
    if (!props.plan.is_pay_once && discount.value) {
        return (props.plan.price * 6 * (discount.value.percent || 100)) / 100
    }

    return 0
})

const formattedCalculatedPrices = computed(() => {
    return symbolCurrency(calculatedPrice.value, props.plan.currency)
        .replace(/,?00+$/, '')
        .split(',')
})

const onChangeSubscription = (item) => {
    router.push({
        name: 'buy-plan.index',
        params: {
            id: item.id,
        },
    })
}

const onResume = () => {
    executeResumePlan().then(() => {
        successNotify({
            title: t('subscriptions.messages.resumeTitle'),
            text: t('subscriptions.messages.resumeDescription'),
        })
        router.push({
            name: 'subscriptions.index',
        })
    })
}
</script>
