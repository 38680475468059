<template>
    <base-sticky-heading class="py-6">
        <template #title>
            <h5 class="text-xl font-medium text-gray-900">
                {{ $t('subscriptions.headline') }}
            </h5>
        </template>

        <div
            v-if="subscription"
            class="flex flex-1 flex-col gap-6 bg-gray-50 p-6"
        >
            <div
                v-if="subscription.endsAt"
                class="rounded-md border border-gray-300 bg-white"
            >
                <div class="flex flex-col gap-3.5 border-b border-gray-300 p-6">
                    <base-alert variant="danger">
                        <template #title="{ titleClass }">
                            <span
                                class="text-sm font-medium"
                                :class="titleClass"
                            >
                                {{
                                    $t(
                                        'subscriptions.index.yourSubscriptionExpired',
                                        {
                                            date: $filters.dateLongHumanFormat(
                                                subscription.endsAt
                                            ),
                                        }
                                    )
                                }}
                            </span>
                        </template>
                    </base-alert>
                    <div class="flex flex-col gap-1">
                        <div class="flex items-center gap-2.5">
                            <span class="text-lg font-medium text-gray-900">
                                {{ subscription.name }}
                            </span>
                            <base-badge label="Expired" variant="danger" />
                        </div>
                        <span class="text-sm font-normal text-gray-500">
                            {{ subscription.description }}
                        </span>
                    </div>
                    <subscriptions-feature-list
                        :features="subscription.features"
                    />
                </div>
                <div class="flex justify-end p-3.5">
                    <base-button
                        variant="success"
                        outline
                        size="sm"
                        @click="onChange"
                    >
                        {{ $t('subscriptions.index.viewPlans') }}
                    </base-button>
                </div>
            </div>
            <div v-else class="grid grid-cols-2 gap-6">
                <div class="rounded-md border border-gray-300 bg-white p-6">
                    <div class="flex flex-col">
                        <div class="mb-1 flex items-center gap-2.5">
                            <span class="text-lg font-medium text-gray-900">
                                {{ subscription.name }}
                            </span>
                            <base-badge label="Active" variant="success" />
                        </div>
                        <span class="mb-6 text-sm font-normal text-gray-500">
                            {{
                                $t('subscriptions.typeSubscription', {
                                    oneTime: subscription.is_pay_once,
                                })
                            }}
                        </span>
                        <div class="mb-3.5 flex flex-col">
                            <div
                                class="flex flex-row items-center justify-between border-b border-gray-300 py-2.5 text-sm font-medium"
                            >
                                <span class="text-gray-900">
                                    {{ subscription.name }}
                                </span>
                                <span class="text-gray-500">
                                    {{
                                        $filters.numberFormat(
                                            subscription.price
                                        )
                                    }}
                                </span>
                            </div>

                            <div
                                v-for="(item, idx) in subscription.discounts"
                                :key="idx"
                                class="flex flex-row items-center justify-between border-b border-gray-300 py-2.5 text-sm font-medium"
                            >
                                <span class="text-gray-900">
                                    {{ item.name }}
                                </span>
                                <span class="text-gray-500">
                                    {{
                                        $filters.numberFormat(
                                            Math.abs(item.amount) * -1
                                        )
                                    }}
                                </span>
                            </div>

                            <div
                                class="flex flex-row items-center justify-between border-b border-gray-300 py-2.5 text-sm font-medium"
                            >
                                <span class="text-gray-900">
                                    {{ $t('subscriptions.index.subTotal') }}
                                </span>
                                <span class="text-gray-500">
                                    {{ $filters.numberFormat(subTotal) }}
                                </span>
                            </div>

                            <div
                                v-for="(item, idx) in subscription.taxes"
                                :key="idx"
                                class="flex flex-row items-center justify-between border-b border-gray-300 py-2.5 text-sm font-medium"
                            >
                                <span class="text-gray-900">
                                    {{ item.name }}
                                </span>
                                <span class="text-gray-500">
                                    {{ $filters.numberFormat(item.amount) }}
                                </span>
                            </div>

                            <div
                                class="flex flex-row items-center justify-between border-b border-gray-300 py-2.5 text-sm font-medium"
                            >
                                <span class="text-gray-900">
                                    {{
                                        $t('subscriptions.typeTotal', {
                                            oneTime: subscription.is_pay_once,
                                        })
                                    }}
                                </span>
                                <span class="text-gray-900">
                                    {{ $filters.numberFormat(total) }}
                                </span>
                            </div>
                        </div>

                        <div
                            class="mb-6 flex flex-col gap-3 text-sm font-medium text-gray-500"
                        >
                            <span v-if="subscription.is_pay_once">
                                {{ $t('subscriptions.index.thisIsOneTime') }}
                            </span>
                            <template v-else>
                                <span>
                                    {{
                                        $t('subscriptions.index.yourNextBill', {
                                            date: $filters.dateLongHumanFormat(
                                                subscription.nextPayAt
                                            ),
                                        })
                                    }}
                                </span>
                                <span>
                                    {{
                                        $t(
                                            'subscriptions.index.autoSubscription'
                                        )
                                    }}
                                </span>
                            </template>
                        </div>
                        <div
                            class="flex flex-row gap-2.5"
                            v-if="!subscription.is_pay_once"
                        >
                            <base-button
                                v-if="$acl.can('update_subscriptions')"
                                :label="
                                    $t('subscriptions.index.changeSubscription')
                                "
                                full-width
                                variant="default"
                                size="sm"
                                @click="onChange"
                            />

                            <base-button
                                v-if="
                                    subscription &&
                                    !subscription.free &&
                                    !subscription.endsAt
                                "
                                full-width
                                :label="
                                    $t('subscriptions.index.cancelSubscription')
                                "
                                size="sm"
                                outline
                                variant="danger"
                                @click="onCancel"
                            />
                        </div>
                    </div>
                </div>
                <div class="rounded-md border border-gray-300 bg-white">
                    <div class="mb-1 flex items-center gap-2.5 p-6">
                        <span class="text-lg font-medium text-gray-900">
                            Addons
                        </span>
                    </div>
                    <div class="pointer-events-none relative">
                        <span
                            class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full border border-warning-400 bg-warning-50 px-3 py-1 text-sm font-medium text-warning-700"
                        >
                            coming late Q4
                        </span>
                        <div class="flex flex-col gap-4 p-6 blur-sm">
                            <div class="flex flex-col">
                                <span class="text-sm font-medium text-gray-900">
                                    1/3
                                </span>
                                <span
                                    class="mb-3 text-xs font-normal text-gray-500"
                                >
                                    Free connections
                                </span>
                                <span
                                    class="mb-3 text-sm font-medium text-gray-500"
                                >
                                    This enables you to connect more than one
                                    bank account to automatically import
                                    transactions.
                                </span>
                                <div class="flex flex-row items-center gap-2.5">
                                    <base-button size="sm" variant="primary">
                                        Buy Addon
                                    </base-button>
                                    <span
                                        class="text-sm font-medium text-gray-800"
                                    >
                                        €2.00 €1.00/Extra bank account
                                    </span>
                                </div>
                            </div>
                            <div class="flex flex-col">
                                <span class="text-sm font-medium text-gray-900">
                                    1/3
                                </span>
                                <span
                                    class="mb-3 text-xs font-normal text-gray-500"
                                >
                                    Free connections
                                </span>
                                <span
                                    class="mb-3 text-sm font-medium text-gray-500"
                                >
                                    This enables you to connect more than one
                                    bank account to automatically import
                                    transactions.
                                </span>
                                <div class="flex flex-row items-center gap-2.5">
                                    <base-button size="sm" variant="primary">
                                        Buy Addon
                                    </base-button>
                                    <span
                                        class="text-sm font-medium text-gray-800"
                                    >
                                        €2.00 €1.00/Extra bank account
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                v-if="isFetched"
                class="rounded-md border border-gray-300 bg-white"
            >
                <h6
                    class="border-b border-gray-300 px-3.5 py-3 text-sm font-medium"
                >
                    {{ $t('subscriptions.index.billing') }}
                </h6>

                <data-grid
                    :columns="billColumns"
                    :data-source="bills"
                    :table-borderless="true"
                    :loading="isFetching"
                >
                    <template #column-date="{ item }">
                        {{ $filters.dateHumanFormat(item.date) }}
                    </template>
                    <template #column-amount="{ item }">
                        {{
                            $filters.symbolCurrency(
                                item.amount,
                                defaultCurrency
                            )
                        }}
                    </template>
                    <template #column-invoice="{ item }">
                        <base-button
                            variant="default"
                            size="sm"
                            @click="onDownloadInvoice(item)"
                        >
                            {{ $t('general.download') }}
                        </base-button>
                    </template>
                </data-grid>

                <div class="p-6">
                    <p class="mb-4 text-md font-normal text-gray-600">
                        {{ $t('subscriptions.index.manageYourBilling') }}
                    </p>
                    <base-button
                        variant="default"
                        :loading="loadingCustomerPortal"
                        @click="onManageBilling"
                    >
                        {{ $t('subscriptions.index.manageBilling') }}
                    </base-button>
                </div>
            </div>
        </div>
    </base-sticky-heading>

    <subscription-success
        :show="state.showSuccessModal"
        @modal-close="state.showSuccessModal = false"
    />
    <subscription-reject
        :show="state.showRejectModal"
        @modal-close="state.showRejectModal = false"
    />
    <subscription-cancel
        :show="state.showCancelModal"
        @finish-cancel="onFinishCancel"
        @modal-close="state.showCancelModal = false"
    />
</template>

<script setup>
import { replaceQuery } from '@tenant/utils/helper'
import { ECHO_EVENTS } from '@tenant/utils/constants'
import BigNumber from 'bignumber.js'
import { useInfiniteQuery } from '@tanstack/vue-query'
import { uniqBy } from 'lodash-es'

const { defaultCurrency } = useCurrencies()
const emitter = useEmitter()
const router = useRouter()
const route = useRoute()
const { t } = useI18n()
const { company } = useAuth()

useHead({ title: t('subscriptions.headline') })

const { execute: executeGetBills } = useApi('/api/subscription/bills', 'GET')
const { execute: executeGetSubscription, subscription } = useSubscription()

const { execute: getCustomerPortal, loading: loadingCustomerPortal } = useApi(
    '/api/subscription/customer-portal',
    'POST'
)

const {
    data: bills,
    isFetched,
    isFetching,
    fetchNextPage,
    hasNextPage,
} = useInfiniteQuery({
    queryKey: ['subscriptions-bills'],
    queryFn: ({ pageParam }) => {
        return executeGetBills({
            queryParams: {
                cursor: pageParam,
            },
        })
    },
    getNextPageParam: ({ next_cursor }) => next_cursor,
    select: (res) =>
        uniqBy(
            res?.pages?.flatMap((x) => x?.data),
            (x) => x?.id
        ),
    refetchOnWindowFocus: false,
    refetchInterval: false,
})

const state = reactive({
    showSuccessModal: false,
    showRejectModal: false,
    showCancelModal: false,
})

const billColumns = [
    {
        property: 'date',
        label: t('subscriptions.index.grid.dateOfTransfer'),
        dataCellClass: 'w-40 pt-1 pb-1',
    },
    {
        property: 'details',
        label: t('subscriptions.index.grid.details'),
        dataCellClass: 'pt-1 pb-1',
    },
    {
        property: 'amount',
        label: t('subscriptions.index.grid.amount'),
        dataCellClass: 'w-48 pt-1 pb-1',
    },
    {
        property: 'invoice',
        label: t('subscriptions.index.grid.invoice'),
        dataCellClass: 'w-40 pt-1 pb-1',
    },
]

const subTotal = computed(() => {
    return new BigNumber(subscription.value.price)
        .minus(
            subscription.value.discounts.reduce((cur, i) => cur + i.amount, 0.0)
        )
        .toNumber()
})

const total = computed(() => {
    return new BigNumber(subTotal.value)
        .plus(subscription.value.taxes.reduce((cur, i) => cur + i.amount, 0.0))
        .toNumber()
})

onMounted(async () => {
    getCompanySubscription()
    handleRouteAction()
})

onMounted(() => {
    emitter.on('scroll-end', onLoadMore)
})

onUnmounted(() => {
    emitter.off('scroll-end', onLoadMore)
})

const onLoadMore = () => {
    if (hasNextPage.value) {
        fetchNextPage()
    }
}

const getCompanySubscription = () => {
    emitter.emit('set-loading', true)
    return executeGetSubscription()
        .then((data) => {
            if (!data && !company.value.paid_once_at) {
                router.push({ name: 'subscriptions.change' })
            }
        })
        .finally(() => {
            emitter.emit('set-loading', false)
        })
}

useListenEmitEcho(ECHO_EVENTS.CUSTOMER_SUBSCRIPTION_CREATED, () => {
    getCompanySubscription()
})

const handleRouteAction = () => {
    const { success, cancel, ...restQuery } = route.query

    if (success) {
        state.showSuccessModal = true
    } else if (cancel) {
        state.showRejectModal = true
    }

    replaceQuery(restQuery)
}

const onChange = () => {
    router.push({
        name: 'subscriptions.change',
    })
}

const onManageBilling = () => {
    getCustomerPortal().then((url) => {
        window.location.href = url
    })
}

const onCancel = () => {
    state.showCancelModal = true
}

const onFinishCancel = () => {
    state.showCancelModal = false
    getCompanySubscription()
}

const onDownloadInvoice = (item) => {
    window.open(item.invoice_pdf, '_blank')
}
</script>
