export default {
    subscriptions: {
        sidebar: {
            label: 'Subscriptions',
        },
        headline: 'Subscriptions',
        features: 'FEATURES',
        perType: ({ named }) => {
            const isOneTime = named('oneTime')
            if (isOneTime) {
                return 'one time'
            }

            return 'per month'
        },
        interval: ({ named }) => {
            const oneTime = named('oneTime')
            if (oneTime) {
                return 'One time'
            }

            return 'Monthly'
        },
        typeSubscription: ({ named }) => {
            const oneTime = named('oneTime')
            if (oneTime) {
                return 'One time subscription'
            }

            return 'Monthly subscription'
        },
        typeTotal: ({ named }) => {
            const oneTime = named('oneTime')
            if (oneTime) {
                return 'One time total'
            }

            return 'Monthly total'
        },
        index: {
            yourSubscriptionExpired:
                'Your Cybooks subscription ended on {date}. Buy a plan to continue using Cybooks.',
            viewPlans: 'View Plans',
            subTotal: 'Sub total',
            thisIsOneTime: 'This is one time so you can use it forever',
            yourNextBill:
                'Your next subscription renewal date is {date} and each month after.',
            autoSubscription:
                'Cybooks subscriptions auto-renew monthly until the plan is cancelled. There is a 1 month notice period required for your cancellation. ',
            changeSubscription: 'Change subscription',
            cancelSubscription: 'Cancel subscription',
            popular: 'Popular',
            billing: 'Billing history',
            manageBilling: 'Manage Billing',
            manageYourBilling:
                'Manage your billing email address, payment method and view invoices with your account email.',
            grid: {
                dateOfTransfer: 'Date of transfer',
                details: 'Details',
                amount: 'Amount',
                invoice: 'Invoice',
            },
        },
        change: {
            yourFreeTrial: 'Your Free Trial Has Ended — It’s Time to Upgrade',
            selectAPlanBelow:
                'Select a plan below to jump back into Cybooks today. Need a hand? Reach out to our support team {here}.',
            supportTeamHere: 'here',
            perMonth: 'per month',
            oneTime: 'one time',
            comingSoon: 'coming soon',
            youAreOnThisPlan: "You're on this plan",
            wasSave: "Was {was} per month{'|'} Save {price} in total",
            offForMonths: '{percent}% Off for {months} Months',
            upgradeAndSave: 'Upgrade & save',
            downgradeAndSave: 'Downgrade & save',
            resumeSubscription: 'Resume subscription',
        },
        successModal: {
            title: 'Thanks for joining Cybooks!',
            description:
                'You successfully subscribed to Cybooks. It may take up to 5 minutes until the subscription is active.',
            button: 'I understood, get started',
        },
        cancelModal: {
            title: 'Cancel my subscription',
            description:
                "We're sad to see you go but, before you leave, we'd appreciate your feedback.",
            button: 'I understood',
            imMovingUnder: 'I’m moving under my accountant’s subscription',
            imMovingToAnother: 'I am moving to another software',
            cybooksFeature: 'Cybooks does not have all the features I require',
            cybooksExpensive: 'Cybooks is too expensive',
            myCompanyClosing: 'My company is closing',
            other: 'Other',
            typeYourReasonHere: 'Type your reason here ...',
            cancelSubscription: 'Yes, cancel subscription',
        },
        rejectModal: {
            title: 'Subscription failure',
            description:
                'There was an error during your subscription. You were not charged.',
            button: 'I understood',
        },
        messages: {
            subscriptionCanceled: 'Subscription canceled.',
            yourSubscriptionIsCanceled:
                'Your subscription is canceled and will expire on the {day} of {month}.',
            subscriptionIsActive: 'Subscription is active.',
            subscriptionIsNowAction: 'Your subscription is now active',

            resumeTitle: 'Subscription resumed.',
            resumeDescription:
                'The stop of your pending cancellation was successfully and you resumed your subscription.',
        },
    },
}
