<template>
    <base-modal close @modal-close="closeModal" :show="show" size="md">
        <template #modal-icon>
            <div class="flex justify-center">
                <base-feature-icon
                    variant="success"
                    name="line-icons:general:check-circle"
                />
            </div>
        </template>
        <div class="mb-3 flex flex-col items-center justify-center gap-2">
            <h5 class="text-lg font-medium text-gray-900">
                {{ $t('subscriptions.successModal.title') }}
            </h5>
            <p class="text-center text-sm font-normal text-gray-500">
                {{ $t('subscriptions.successModal.description') }}
            </p>
        </div>
        <template #modal-footer>
            <base-button
                class="w-full justify-center"
                variant="success"
                @click="closeModal"
            >
                {{ $t('subscriptions.successModal.button') }}
            </base-button>
        </template>
    </base-modal>
</template>

<script setup>
const emit = defineEmits(['modal-close'])

defineProps({
    show: {
        type: Boolean,
        default: false,
    },
})

const closeModal = () => {
    emit('modal-close')
}
</script>
