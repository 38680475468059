<template>
    <base-modal
        close
        @modal-close="closeModal"
        :show="show"
        :loading="loading"
        size="lg"
    >
        <template #modal-header>
            <div class="flex flex-col gap-2">
                <span class="text-lg font-medium text-gray-900">
                    {{ $t('subscriptions.cancelModal.title') }}
                </span>
                <span class="text-sm font-normal text-gray-500">
                    {{ $t('subscriptions.cancelModal.description') }}
                </span>
            </div>
        </template>
        <div class="flex flex-col gap-2.5">
            <form-radio
                v-model="reasonValue"
                variant="square"
                :text="$t('subscriptions.cancelModal.imMovingUnder')"
                :value="$t('subscriptions.cancelModal.imMovingUnder')"
                name="reason"
            />
            <form-radio
                v-model="reasonValue"
                variant="square"
                :text="$t('subscriptions.cancelModal.imMovingToAnother')"
                :value="$t('subscriptions.cancelModal.imMovingToAnother')"
                name="reason"
            />
            <form-textarea
                v-if="
                    reasonValue ===
                    $t('subscriptions.cancelModal.imMovingToAnother')
                "
                v-model="reasonNote"
                :placeholder="
                    $t('subscriptions.cancelModal.typeYourReasonHere')
                "
                rows="3"
                class="text-sm"
                :error-message="errorNote"
            />

            <form-radio
                v-model="reasonValue"
                variant="square"
                :text="$t('subscriptions.cancelModal.cybooksFeature')"
                :value="$t('subscriptions.cancelModal.cybooksFeature')"
                name="reason"
            />
            <form-textarea
                v-if="
                    reasonValue ===
                    $t('subscriptions.cancelModal.cybooksFeature')
                "
                v-model="reasonNote"
                :placeholder="
                    $t('subscriptions.cancelModal.typeYourReasonHere')
                "
                rows="3"
                class="text-sm"
                :error-message="errorNote"
            />

            <form-radio
                v-model="reasonValue"
                variant="square"
                :text="$t('subscriptions.cancelModal.cybooksExpensive')"
                :value="$t('subscriptions.cancelModal.cybooksExpensive')"
                name="reason"
            />
            <form-radio
                v-model="reasonValue"
                variant="square"
                :text="$t('subscriptions.cancelModal.myCompanyClosing')"
                :value="$t('subscriptions.cancelModal.myCompanyClosing')"
                name="reason"
            />

            <form-radio
                v-model="reasonValue"
                variant="square"
                :text="$t('subscriptions.cancelModal.other')"
                :value="$t('subscriptions.cancelModal.other')"
                name="reason"
            />
            <form-textarea
                v-if="reasonValue === $t('subscriptions.cancelModal.other')"
                v-model="reasonNote"
                :placeholder="
                    $t('subscriptions.cancelModal.typeYourReasonHere')
                "
                rows="3"
                class="text-sm"
                :error-message="errorNote"
            />
        </div>
        <template #modal-footer>
            <div class="flex justify-between gap-3">
                <base-button
                    outline
                    full-width
                    variant="default"
                    @click="closeModal"
                >
                    {{ $t('general.back') }}
                </base-button>
                <base-button
                    full-width
                    :disabled="disabled"
                    @click="onCancelSubscription"
                >
                    {{ $t('subscriptions.cancelModal.cancelSubscription') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
import { isEmpty } from 'lodash-es'
import { getDate } from '@tenant/utils/day'

const emit = defineEmits(['modal-close', 'finish-cancel'])

defineProps({
    show: {
        type: Boolean,
        default: false,
    },
})

const { t } = useI18n()
const { successNotify } = useNotification()

const { handleSubmit, setErrors } = useForm()
const { value: reasonValue } = useField('reason')
const {
    value: reasonNote,
    errorMessage: errorNote,
    setValue: setNote,
} = useField('note')

const { execute, loading } = useApi('/api/subscription/cancel', 'POST')

const disabled = computed(() => isEmpty(reasonValue.value))

watch(
    () => reasonValue.value,
    () => {
        setNote(null)
    }
)

const onCancelSubscription = handleSubmit((values) => {
    const { note, reason } = values

    execute({ data: { note, reason } })
        .then((data) => {
            const date = getDate(data)

            successNotify({
                title: t('subscriptions.messages.subscriptionCanceled'),
                text: t('subscriptions.messages.yourSubscriptionIsCanceled', {
                    day: date.format('Do'),
                    month: date.format('MMMM'),
                }),
            })
            emit('finish-cancel', date)
        })
        .catch(({ errors }) => {
            setErrors(errors)
        })
})

const closeModal = () => {
    emit('modal-close')
}
</script>
